<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">请选择单据类型</span>
      <div class="row">
        <div class="col-md-12 wfl_lis">
          <ul class="icon-list">
            <li @click="openWin(it.SN)" v-for="it in ls_conf" :key="it.ID">
                <span>
                  <i :class="it.ICON_PC"/>
                  <span class="icon-name">{{it.NAME}}</span>
                </span>
            </li>
          </ul>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      ls_conf:[]
    }
  },
  methods:{
    init() {
      this.sta = {show: true, loading: false}
      if (this.ls_conf.length==0)
        this.getConf();
    },
    getConf(){
      let self = this;
      this.sta.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/WFL/WConfigApi/GetList",
        data: {},
        completed: function (its) {
          self.ls_conf=its;
        }
      })
    },
    openWin(name){
      this.sta={
        show:false,
        loading:false
      };
      this.$parent.$refs[name].init(name);
    }
  }
}
</script>
<style>
.wfl_lis{padding: 20px 200px}
.wfl_lis ul.icon-list {
  overflow: hidden;
  list-style: none;
  padding: 0!important;
  border: 1px solid #eaeefb;
  border-radius: 4px;
}
.wfl_lis ul:not(.timeline) {
  margin: 10px 0;
  padding: 0 0 0 20px;
  font-size: 14px;
  color: #5e6d82;
  line-height: 2em;
}
.wfl_lis .icon-list li {
  float: left;
  width: 33.5%;
  text-align: center;
  height: 120px;
  line-height: 120px;
  color: #666;
  font-size: 15px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-right: -1px;
  margin-bottom: -1px;
  cursor: pointer;
}
.wfl_lis .icon-list li i {
  display: block;
  font-size: 50px;
  margin-bottom: 15px;
  color: #606266;
  transition: color .15s linear;
}
.wfl_lis .icon-list li span {
  line-height: normal;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
  color: #99a9bf;
  transition: color .15s linear;
}
.wfl_lis .icon-list li .icon-name {
  display: inline-block;
  padding: 0 3px;
  height: 1em;
  color: #4b5257;
}
.wfl_lis .icon-list li:after, .icon-list li span {
  display: inline-block;
  vertical-align: middle;
}
</style>
