<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新采购申请单</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>物品名称</b></label>
            <el-input type="text" v-model="model.BODY.NAME" />
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <el-input type="textarea" v-model="model.BODY.DESCRIPTION" :rows="2" />
          </div>
          <div class="form-group">
            <label><b>数量</b></label>
            <div>
              <el-input-number v-model="model.BODY.CNT" :step="1"></el-input-number>
            </div>
          </div>
          <div class="form-group">
            <label><b>金额</b></label>
            <div>
              <el-input-number v-model="model.BODY.AMOUNT" :step="1"></el-input-number>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {BODY:{}},
    }
  },
  methods:{
    init(sn) {
      //let self = this;
      this.sta={show:true,loading:false}
      this.model = {CONF_SN:sn,BODY:{CNT: 1,AMOUNT:0}};
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/ActApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
